.TopUsageGraph {
  font-size: 0.75em;
  position: relative;
  .graphContainer {
    position: relative;
  }
  svg:hover {
    g.dataRow {
      opacity: 0.6;
      transition: 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .tooltip {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em 1em 0.5em 1em;
    border: 1px solid #D9D9D9;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    z-index: 100;
    width: 260px;
    .tooltip-header {
      font-weight: bold;
      font-size: 1.125em;
      margin-bottom: 0.25em;
      max-width: 180px;
    }
    .tooltip-row {
      display: flex;
      margin-bottom: 0.5em;
      margin-left: 0.25em;
      font-weight: bold;
      > div {
        margin-right: 0.3em;
      }
    }
    .tooltip-row-diamond {
      width: 1em;
      height: 1em;
      transform: rotate(45deg) translateY(20%);
      margin-right: 0.5em !important;
    }

    .tooltip-institution-data-header {
      font-weight: bold;
      border-bottom: 1px solid #000;
      margin-bottom: 0.5em;
      margin-top: 1em;
    }
    .tooltip-institution-data {
      max-width: 20em;
    }
    .tooltip-year-label {
      margin-bottom: 0.2em;
      font-weight: bold;
    }
  }
}
