body {
  scroll-padding-top:100px;
}

.About {
  max-width: 70em;
  margin: 0 auto;
  padding: 0 1em;

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;

  }
  td {
    padding: 0.25em;
  }
  p {

	  scroll-margin-top: 90px;
  }

  a {
    color: #005080;
  }
}
