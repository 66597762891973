
.Footer {
  margin-top:25px;
  color:rgb(60, 60, 60);
  text-align: left;
  background-color: #F0F0F0;
  padding: 25px;
  transition: opacity 0.3s ease-in-out;
  display:flex;
  justify-content:space-around;

  position: absolute;
  bottom: 0;
  width: 100%;

  section {
    width:550px;
    display:flex;
  }
  .left-footer div {
    border-left: 1px solid gray;
    margin-left: 20px;
    padding-left: 20px;
    margin-top: 10px;
  }
  .right-footer {
    flex-direction: column;
    align-items: flex-end;
    p {
      color:#a3a3a3;
      margin-bottom:5px;
    }
  }
  > div {
    padding: 0.2em 0;
  }
  .logo {
    width: 12em;
    align-self:center;
  }
  &.introFooter {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
  }

  .social {
    white-space: nowrap;
    padding-right: 1em;
    margin-top:25px;
    a {
      display: inline-block;
      + a {
        margin-left: 1em;
      }
    }
    img {
      width: 1.75em;
    }
  }

  @media screen and (max-width: 800px) {
    .logo {
      width: 8em;
    }
    font-size: 0.8em;
    > div {
      padding: 0.1em 0;
    }
  }
  @media screen and (max-width: 1000px) {
    .social {
      margin-top:50px;
    }
  }
}
