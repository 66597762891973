
.intro {
  position: relative;
  text-align: center;
  flex-grow: 1;
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
  }
  .introMap {
    width: 100vw;

    opacity: 0;
    transition: opacity 1s ease-in-out;
    &.visible {
      opacity: 1;
    }
  }
  .introText {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 36em;
    p {
      text-align:justify;
    }
    > * {
      padding: 1em;

      background-color: rgba(255,255, 255, 0.6);
      transition: opacity 1s ease-in-out;
      opacity: 0;
      margin: 0;
      &.visible {
        opacity: 1;
      }
    }
    .blue {
      color:#4057C9;
    }
  }

  h1 {
    margin: 0;
    font-size: 1.1em;
  }
  .highlight {
    font-weight: bold;
  }

  .buttons {

    &.fixed {
      position: fixed;
      bottom: 0em;
      left: 50%;
      transform: translate(-50%, 0);
      &.mobileLayout {
        z-index: 1000;
      }
    }
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 100;
    padding: 1em 1em 2em;

    &.finalBoxInView {
      padding: 2em 1em 8.5em;

      &.mobileLayout {
        padding: 1em 1em 9em;
        font-size: 0.8em;
      }
    }
    width: 100%;

    &.introDismissed {
      background:rgba(255,255, 255, 0);
      background-image: -webkit-gradient(linear, left center, left top, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));

    }

    &.mobileLayout.introDismissed {
      background-image:none;
      background-color: rgba(255,255, 255, 0.9);

    }
    &.visible {
      opacity: 1;
    }
    > button, > a {
      appearance: none;
      border: none;
      background-color: #F0F0F0;
      font-weight: bold;
      padding: 0.5em 1em;
      color: #000;
      text-decoration: none;
      font-size: 1em;
      cursor: pointer;
      + button, + a {
        margin-left: 1em;
      }
    }

    .scrollToContinue {
      font-size: 0.7em;
      color: #222222;
      position: absolute;
      bottom: 1.5em;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      opacity: 0;
      transition: 0.3s ease-in-out opacity;
      &.visible {
        opacity: 1;
      }

    }

    .finalExplore {
      opacity: 0;
      pointer-events: none;
      transition: 0.3s ease-in-out opacity;
      &.visible {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .restOfIntro {
    max-width: 50em;
    margin: 0 auto;
    padding: 0 1em;
    margin-top: 3em;
    transition: 1s ease-in-out opacity;
    section {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
    }
    h2 {
      font-size: 1.1em;
      padding: 1em 0 0em;
      background-image: -webkit-gradient(linear, left center, left bottom, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));

    }
    p {
      padding: 1em 0;
      text-align:left;
      margin:0;
    }
    .chart-title {
      text-align: left;
      margin-bottom:5px;
    }
    .intro-graph {
      max-width:100%;
    }
    .intro-caption {
      font-size:.8em;
      font-style:italic;
      padding:0 6% 0 4%;
    }
  }


  .fossilFuel {
    background-color: #F4A77E;
    fill: #F4A77E;
    font-weight: bold;
  }

  .clean {
    background-color: #63CAD1;
    fill: #63CAD1;
    font-weight: bold;
  }


  svg.fuelTypes {
    position: fixed;
    z-index: 100;
    left: 0;
    pointer-events: none;


    text {
      font-weight: bold;
    }

    g {
      transition: 1s ease-in-out opacity;

    }

    text {
      transition: 1s ease-in-out opacity, 1s ease-in-out transform;
    }

  }

  @media (max-width: 1350px) {
    .restOfIntro {
      width: 45em;
    }
    .loading {
      font-size: 1.0em;
    }
  }
  @media (max-width: 1200px) {
    .restOfIntro {
      width: 40em;
    }
  }
  @media (max-width: 1050px) {
    .restOfIntro {
      width: 36em;
    }
  }
  @media (max-width: 800px) {
    .restOfIntro {
      width: 30em;
      max-width:95%;
    }
  }
  @media (max-width: 1000px) {
    .introText {
      width: 24em;
    }

  }
}
