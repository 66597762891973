.IntroMap {
  font-size: 0.75em;
  position: relative;
  transition: 1s ease-in-out opacity;
  svg.map {
    text {
      pointer-events: none;
      text-shadow: 0 1px 1px white, 0 -1px 1px white, 1px 0 1px white, -1px 0 1px white;
    }

    path {
      transition: fill 1s ease-in-out;
    }
  }

  strong {
    font-weight: bold;

    &.large {
      font-size: 1.5em;
    }
  }



}
