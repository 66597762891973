@import './fonts/fonts.scss';

.App {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
* {
  box-sizing: border-box;
}

h1 {
  font-size:3em;
  margin-top:0;
  margin-bottom:0;
}

h3 {
  font-size:1.25em;
}

h2 {
  font-size:2em;
  margin-top: 0;
  margin-bottom: 12px;
  img {
    margin-left: 8px;
    &:hover {
      opacity: 0.5;
    }
  }
}

select {
  color: black;
}

.helperTooltip {
  background-color: #F7FBFD !important;
  border: 1px solid #D9D9D9 !important;
  padding: 0.5em !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16) !important;
  max-width: 23em;
  color: #000 !important;
  opacity: 0.95 !important;
}

a {
  color: #005080;

}
