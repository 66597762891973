header.Header {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  position: fixed;
  background-color: #fff;
  z-index: 10;
  top: 0;
  width: 100%;
  > div {
    display: flex;
  }
  img {
    width: 15em;
    align-self: center;
    margin:10px;
  }
  h1 {
    margin: 0;
    margin-left: 1em;
    align-self: center;
    margin-top: 0.3em;
    font-weight: normal;
    white-space: nowrap;
  }

  nav {
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    a {
      white-space: nowrap;
      color: #222;
      padding: 1.7em 0em 0;
      margin: 0 1em;
      text-decoration: none;
      height: 3.3em;
      &.active, &:hover {
        color: #4057C9;
        border-bottom: 2px solid #4057C9;
      }
    }
  }

  select.menu {
    display: none;
    margin-left: -1rem;
    appearance: none;
    width: 100vw;
    font-size: 1rem;
    background: #F0F0F0 url('../images/menu.png') 0 0 no-repeat;
    background-size: 105px;
    border: none;
    height: 27px;
    margin-top: 1em;
    padding-left: 115px;
    font-weight: bold;
  }


  @media (max-width: 1310px) {
    h1 {
      font-size: 1.25em;
    }
    nav a {
      padding-top: 1.2em;
      height: 3em;
    }
    .social {
      a {
        padding-top: 1.2em;
      }
    }
  }
  @media (max-width: 1160px) {
    h1 {
      font-size: 1em;
    }
  }

  @media (max-width: 1100px) {
    > div {
      display: block;
    }
    img {
      width: 12em;
      margin:0;
    }
    h1 {
      margin-left: 0;
      font-size: 1.25em;
    }
    nav {
      display: none;
    }

    select.menu {
      display: block;
    }
    .right {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
