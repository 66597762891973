@font-face {
  font-family: Diavlo;
  src: url(./Diavlo_BOOK_II_37.otf);
  font-weight: 400;
}
@font-face {
  font-family: Diavlo;
  src: url(./Diavlo_BOLD_II_37.otf);
  font-weight: 700;
}


h1 {
  font-weight: 700;
}

