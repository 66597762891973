.FinanceTracker {
  padding: 0 1em 1em;

  .description {
    max-width:70em;
    margin:0 auto;
    padding:0 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 0.5em;
    thead {
      font-size: 0.7em;
      font-weight: bold;
      td {
        vertical-align: bottom;
        padding-bottom: 0.2em;
      }
    }

    td:first-child {
      padding-left: 1rem;
    }

    tbody {
      tr.table td {
        cursor: pointer;
        border: 1px solid black;
        margin-bottom: 0.5em;
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding: 0.2em 0;

        &:first-child {
          border-left: 1px solid black;
          padding-left: 1rem;
        }
        &:last-child {
          border-right: 1px solid black;
        }
      }
    }

    .policies td {
      padding: 0;
      border: 1px solid black;
      border-top: none;

      > div {

        overflow: hidden;
        max-height: 0;
        transition: 0.3s ease-in-out max-height;
        &.open {
          max-height: 100em;
        }
      }
      .policyList {
        display: flex;
        width: 100%;

        .policy {
          padding: 1em;
          div:first-child {
            font-weight: bold;
            margin-bottom: 0.5em;
          }
          width: 25%;

          + div {
            margin-left: 0.25em;
          }
        }
      }
    }
    .spacer td {
      padding-bottom: 0.3em;
    }
  }
  .sources {
    margin-top: 0.25em;
    padding: 1em;
    background-color: #F0F0F0;
    font-size: 0.9em;
    p {
      font-size: 0.8rem;
      margin-bottom: 0;
      word-break: break-all;
    }
    a {
      color: #005080;
    }
    a::after {
      content:"\A";
      white-space:pre;
    }
  }
  .dot {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    opacity: 1; // was .5 previously
    margin: 0 auto;
    display: block;
  }
  .controls {
    display: flex;
    justify-content: space-between;

    max-width: 70em;
    flex-wrap: nowrap;
    @media (max-width: 650px) {
      width: auto;
    }
    .Select {
      margin-bottom: 0;
    }
  }
  .legend {
    font-size:.875em;
    background-color: #F0F0F0;
    padding: 0.5em;
    align-self: flex-end;
    .legend-title {
      font-weight: bold;
      margin-bottom: 0.5em;
    }
    .legend-items {
      display: flex;
      > div {
        display: flex;
        .dot {
          font-size:1em;
          margin-right: 0.5em;
          @media (min-width:768px) {
          }
        }
        + div {
          margin-left: 2.5em;
        }
      }
    }
  }

  .tableContainer {
    position: relative;
    max-width:70em;
    margin: 0 auto;
    margin-top: -2em;
    .dotTooltip {
      position: absolute;
      left: 0;
      top: 0;

      max-width: 20em;
      pointer-events: none;

      z-index: 10;
      border: 1px solid #D9D9D9;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      background-color: #fff;
      .dotTooltip-content {
        padding: 0.5em;
      }
      .dot-tooltip-title {
        font-weight: bold;
        font-size: 0.9em;
      }
      .dot-tooltip-description {
        font-size: 0.8em;
      }
    }

    .hoverInstructions {
      width: 15em;
      text-align: center;
      transform: translate(1.9em, 1.7em) ;
    }
  }


  &.singleColumnView {
    font-size: 0.9em;
    .legend {
      .legend-items {
        flex-direction: column;
        .dot {
          margin-left: 0;
        }
        > div {
          + div {
            margin-left: 0;
            margin-top: 0.5em;
          }
        }
      }
    }
    tr.table {
      td:first-child {
        padding-right: 0.5em;
      }
    }
    tr.policies td {
      .policyList {
        flex-direction: column;

        &.open {
          max-height: 80em;
        }
        > div {
          width: auto;
          + div {
            margin-left: 0;
          }
        }
      }
    }
  }

  .filter-control {
    display: inline-flex;
    align-items: center;
    padding: 0.5em;
    border-radius: 0.25em;
    background-color: #F0F0F0;
    font-weight: bold;
    font-size: 0.8em;
    margin: 0.5em 0;
    cursor: pointer;
    .filter-control-switch {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 0.5em;
      background: url('../images/check-square-solid.png') 0 0 no-repeat;

    }
    &.checked {
      background-color: #DAECF7;
      .filter-control-switch {
        background: url('../images/check-square-checked.png') 0 0 no-repeat;

      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
      .filter-control-switch {
        background: url('../images/check-square-solid.png') 0 0 no-repeat;

      }
    }
    img {
      margin-left: 0.5em;
    }
    +.filter-control {
      margin-left: 0.5em;
    }
  }
}
.sortBy {
  // display:inline;
  @media (max-width: 768px) {
    display:block;
  }
}