.Research {
  max-width: 70em;
  margin: 0 auto;
  padding: 0 1em;

  a {
    color: #005080;
  }

  .imageContainer {
    text-align:center;
  }

  p.hasImage {
    display: flex;
    justify-content: center;
    .imageContainer {
      position: relative;
      +.imageContainer {
        margin-left: 1em;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
