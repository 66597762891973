.dataViewIntro {
  margin: 0 auto;
  max-width:70em;
  padding: 0 10px;
}
.DataView {

  @media (max-width: 1000px) {
    padding:0;
  }

  &.twoColumnView {
    // columns: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width:100em;
    margin: 0 auto;
  }

  section {
    margin: .5em;
    border: 1px solid #dcdcdc;
    padding:20px;
    .description {
      margin-bottom: 12px;
    }

  }

  svg {
    overflow: visible;
  }




}
.disclaimer {
  font-size: 80%;
  padding: 1em;
  text-align:center;
}

.energyTypeToggle {
  margin-left: 1rem;
  display: inline-block;
  padding: 0.2em 0.5em;
  font-weight: bold;
  white-space: nowrap;
  font-size: 0.875em;
  cursor: pointer;
  border-radius: 0.25em;
  transition: opacity 0.3s ease-in-out;
  &:first-of-type {
    color:white;
    .Checkbox {
      background-color:white;
      border:1px solid white;
    }
  }
  .Checkbox {
    margin-right: 0.5em;
  }
}

.controls {
  display: flex;
  margin:0 auto 2em;
  max-width:50em;
  flex-wrap:wrap;
  justify-content:center;
  padding-left:10px;
  @media (max-width: 1200px) {
    .energyTypeToggle:first-of-type {
      margin-left: 1rem;
    }

  }
  @media (max-width: 650px) {
    font-size: 0.85em;
    flex-direction:column;
    margin-left:0;
    width:300px;

    .year-picker  {
      margin-top: 0.5em;
      display: flex;
      flex-direction: column;
      > span {
        margin-left: 0;
        border-left: 0;
      }
    }

    .energyTypes {
      margin-top:10px;
      svg[data-tip] {
        display: none;
      }
    }
    .energyTypeToggle:first-of-type {
      margin-left: 0;
    }
  }
}
